'use client';

import { useAuth } from '@/hooks/auth.hook';
import { useRouter } from '@/hooks/useRouter';

export default function AuthLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const router = useRouter();
  const { firstLoading, profile } = useAuth();

  if (firstLoading) return <p>Loading....</p>;

  if (!firstLoading && profile) return router.replace('/');

  return <>{children}</>;
}
